<main role="main" class="container py-3 mt-md-4 py-md-4" [style.background]="safeBackground">
    <div class="row">
        <div class="{{wrapperClasses}} mx-auto bg-white tw-rounded-xl">
            <div class="px-md-4 py-5">



                <div *ngIf="showBranding" class="text-center pb-4">
                    <ng-container *ngIf='showLprxLogo'>
                        <img [src]="brand.safeLogo" style="max-width: 80%" alt="{{brand.name}}">
                    </ng-container>
                    <ng-container *ngIf='!showLprxLogo'>
                        <img src='/assets/img/lprx-logo.png' style="max-width: 80%" alt="Liferay">
                    </ng-container>
<!--                    <a href="{{brand.baseUrl}}"><img [src]="brand.safeLogo" style="max-width: 80%" alt="{{brand.name}}"></a>-->

                </div>

                <ng-content></ng-content>
            </div>
        </div>
    </div>
</main>
