import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, EMPTY as empty, from, from as fromPromise, Observable, of } from 'rxjs';
import { expand, map, reduce, switchMap, tap } from 'rxjs/operators';
import { Stripe } from 'stripe';
import { ClientCoupon } from '../../lprx-shared-lib/client-coupon';
import { DistributorApplication } from '../../lprx-shared-lib/distributor-application';
import { DistributorCreditTransaction } from '../../lprx-shared-lib/distributor-tokens/distributor-token-transaction';
import { DistributorUpdateLogoDto } from '../../lprx-shared-lib/distributor-update-logo.dto';
import { DistributorUpdatePhotoDto } from '../../lprx-shared-lib/distributor-update-photo.dto';
import { DistributorUpdateProfileDto } from '../../lprx-shared-lib/distributor-update-profile';
import { CreateClientDto } from '../../lprx-shared-lib/dto/create-client.dto';
import { DistributorApplicationApprovalOptions } from '../../lprx-shared-lib/dto/distributor-application-approval-options';
import { DistributorProfileSetupCommand } from '../../lprx-shared-lib/dto/distributor-profile-setup.command';
import { BundleClient } from '../../lprx-shared-lib/entities/bundle-client';
import { Bundle, DistributorBundle } from '../../lprx-shared-lib/entities/bundle/bundle';
import { Distributor } from '../../lprx-shared-lib/entities/distributor';
import { FullscriptCreateDynamicLinkResponse } from '../../lprx-shared-lib/entities/fullscript-create-dynamic-link-response';
import {
  FullscriptTreatmentPlan,
  IFullscriptPatientListItem,
  IFullscriptPractitioner,
} from '../../lprx-shared-lib/entities/i-fullscript-practitioner';
import { ListPractitionerResults } from '../../lprx-shared-lib/entities/list-practitioner-results';
import { MealPlan } from '../../lprx-shared-lib/entities/meal-plan/MealPlan';
import { PayoutAccount } from '../../lprx-shared-lib/entities/payout-account';
import { NutritionFactsDisplay } from '../../lprx-shared-lib/entities/user/nutrition-facts-display';
import {
  EditTeamMemberRequest,
  PractitionerUserInterface,
  User,
} from '../../lprx-shared-lib/entities/user/user';
import { PlannerConfigNutrientLimits } from '../../lprx-shared-lib/planner/planner-configuration';
import { UpdatePricingDto } from '../../lprx-shared-lib/update-pricing.dto';
import { DEFAULT_LIST_LIMIT } from '../../lprx-shared-lib/utils/constants';
import { ListResult } from '../../lprx-shared-lib/utils/list-result';
import { LprxApiProvider } from '../../providers/lprx-api/api-provider';
import { alertHttpError } from '../utilities/alert-http-error';
import { DistributorAccountService } from './distributor/distributor-account.service';
import { ClientWithStatus } from '../../lprx-shared-lib/utils/types';
import { MealPlannerSetupCommand } from '../../lprx-shared-lib/dto/meal-planner-setup-command';
import { DistributorProfileSetupResponse } from '../../lprx-shared-lib/dto/distributor-profile-setup-response';
import {
  GetVioscreensResponse,
  ViocareSessionTokenResponse,
  VioscreenEntity,
  VioscreenReportType,
} from '../../lprx-shared-lib/viocare';
import { sanitizeEmail } from '../../lprx-shared-lib/sanitize-email';
import { GetTdCeuCouponCodeResponse } from '../../lprx-shared-lib/entities/td-ceu-coupon-code';
import { Result } from '../../lprx-shared-lib/result';
import { ChangePrimaryPractitionerResponse } from '../../lprx-shared-lib/dto/change-primary-practitioner-response';

@Injectable()
export class DistributorsService {
  constructor(
    private lprxApi: LprxApiProvider,
    public account: DistributorAccountService,
    private toastr: ToastrService
  ) {}

  private clientsCache: Map<string, User> = new Map<string, User>();

  private currentDistributor = new BehaviorSubject<Distributor>(null);
  public readonly currentDistributor$ = this.currentDistributor.asObservable().pipe(
    switchMap((distributor) => {
      // If the distributor isn't defined yet, attempt to get the distributor
      //   from the current logged in user via the api
      return distributor ? of(distributor) : fromPromise(this.getCurrent());
    })
  );

  getCurrent(): Promise<Distributor> {
    return this.lprxApi.distributors
      .current()
      .then((distributor: Distributor) => {
        this.currentDistributor.next(distributor);
        return distributor;
      })
      .catch((e: Error) => {
        this.currentDistributor.next(null);
        return null;
      });
  }

  getCurrent$() {
    return fromPromise(this.getCurrent());
  }

  getClients(): Observable<User[]> {
    return fromPromise(this.lprxApi.clients.get()).pipe(
      tap((users) => {
        this.clientsCache.clear();
        for (const u of users) {
          this.clientsCache.set(u.username, u);
        }
      })
    );
  }

  getClientWithStatus(): Observable<ListResult<ClientWithStatus>> {
    return fromPromise(this.lprxApi.clients.getClientsWithStatus());
  }

  getClient(username: string, useCache = true): Observable<User> {
    if (this.clientsCache.has(username) && useCache) {
      return of(this.clientsCache.get(username));
    }

    return fromPromise(this.lprxApi.clients.getClient(username)).pipe(this.cacheClient());
  }

  getMealPlan(id: string) {
    return fromPromise(this.lprxApi.mealPlans.get(id));
  }

  getMealPlans(limit = DEFAULT_LIST_LIMIT, nextKey?: string) {
    // const plans: MealPlan[] = [];
    return fromPromise(this.lprxApi.mealPlans.list(limit, nextKey)).pipe(
      expand((result: ListResult<MealPlan>): Observable<ListResult<MealPlan>> => {
        console.log(result.items.length);
        if (result.items.length) {
          // plans.push(...result.items);
        }
        if (result.has_more) {
          return fromPromise(this.lprxApi.mealPlans.list(750, result.next_key));
        } else {
          return empty;
        }
      }),
      reduce((acc: MealPlan[], value: ListResult<MealPlan>, index) => {
        acc.push(...value.items);
        return acc;
      }, []),
      map((acc) => acc.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))),
      switchMap((acc) => of(new ListResult(acc)))
    );
  }

  changeClientNutritionFactsDisplay(client: User, nutritionFactsDisplay: NutritionFactsDisplay) {
    const promise = this.lprxApi.clients.changeNutritionFactsDisplay(
      client.username,
      nutritionFactsDisplay
    );
    return fromPromise(promise).pipe(this.cacheClient());
  }

  cacheClient(): (source: Observable<User>) => Observable<User> {
    return tap((client: User) => this.clientsCache.set(client.username, client));
  }

  cleanUserAccount(id: string) {
    return this.lprxApi.distributors.cleanUserAccount(id);
  }

  getPaymentCards(): Observable<Stripe.Card[]> {
    return fromPromise(this.lprxApi.distributors.getCards());
  }

  saveMealPlan(mealPlan: MealPlan) {
    return fromPromise(this.lprxApi.mealPlans.save(mealPlan));
  }

  get(distributorId: string) {
    return fromPromise(this.lprxApi.distributors.get(distributorId));
  }

  getCreditTransactions(distributorId: string) {
    return fromPromise(this.lprxApi.credits.list(distributorId));
  }

  getCreditBalance(distributorId?: string) {
    return fromPromise(this.lprxApi.credits.balance(distributorId));
  }

  addCredits(
    distributorId: string,
    amount: number,
    memo: string
  ): Observable<DistributorCreditTransaction> {
    return fromPromise(this.lprxApi.credits.credit(distributorId, amount, memo));
  }

  getBundle(bundleId: string): Observable<DistributorBundle> {
    return fromPromise(this.lprxApi.bundles.get(bundleId));
  }

  getBundleClients(bundleId: string): Observable<BundleClient[]> {
    return fromPromise(this.lprxApi.bundles.clients(bundleId));
  }

  delete(bundleId: string) {
    return fromPromise(this.lprxApi.bundles.delete(bundleId));
  }

  /**
   *
   * @param distributorId
   */
  getPayoutAccount(distributorId: string): Observable<PayoutAccount> {
    return fromPromise(this.lprxApi.get(`appApi/distributor/connect/client-id/${distributorId}`));
  }

  /**
   *
   */
  getStripeLoginUrl(): Observable<string> {
    return fromPromise(this.lprxApi.get(`v3/account/stripe-dashboard`).then(({ url }) => url));
  }

  /**
   *
   * @param bundleId
   * @param source
   * @param applyCredits
   */
  purchaseBundle(bundleId: string, source: string, applyCredits: number): Observable<Bundle> {
    return fromPromise(this.lprxApi.bundles.purchase(bundleId, source, applyCredits));
  }

  getAffiliateDashboard() {
    return fromPromise(this.lprxApi.distributors.getAffiliateDashboard());
  }

  getAffiliateAccount() {
    return fromPromise(this.lprxApi.distributors.getAffiliateAccount());
  }

  /**
   *
   * @param id
   */
  getApplication(id): Observable<DistributorApplication> {
    return fromPromise(
      this.lprxApi
        .get(`appApi/admin/distributors/pending-applications/${id}`)
        .then((distApp) => plainToClass(DistributorApplication, distApp) as DistributorApplication)
    );
  }

  rejectApplication(distApplication: DistributorApplication) {
    return fromPromise(this.lprxApi.distributorApplications.reject(distApplication.id));
  }

  listPendingApps(): Observable<ListResult<DistributorApplication>> {
    return fromPromise(this.lprxApi.distributorApplications.list());
  }

  approveApplication(
    distApplication: DistributorApplication,
    options?: DistributorApplicationApprovalOptions
  ) {
    return fromPromise(this.lprxApi.distributorApplications.approve(distApplication.id, options));
  }

  profileSetup(profile: DistributorProfileSetupCommand) {
    return fromPromise(
      this.lprxApi.post<DistributorProfileSetupResponse>(`v3/distributors/onboard`, profile)
    );
  }

  mealPlannerSetup(profile: MealPlannerSetupCommand) {
    return fromPromise(this.lprxApi.post(`v3/distributors/meal-planner-setup`, profile));
  }

  updateReferrer(referrerId: string): Observable<Distributor> {
    return fromPromise(this.lprxApi.post<Distributor>(`v3/distributors/referrer`, { referrerId }));
  }

  fullscriptCurrentPractitioner(): Observable<IFullscriptPractitioner> {
    return fromPromise(this.lprxApi.get(`v3/fullscript/account`));
  }

  fullscriptAvailablePractitioners(): Observable<ListPractitionerResults> {
    return fromPromise(this.lprxApi.get(`v3/fullscript/practitioners`));
  }

  fullscriptRevoke() {
    return fromPromise(this.lprxApi.delete('v3/fullscript/account')).pipe(alertHttpError());
  }

  fullscriptTreatmentPlans(client: User): Observable<FullscriptTreatmentPlan[]> {
    return fromPromise(this.lprxApi.get(`v3/fullscript/treatment-plans/${client.username}`));
  }

  fullscriptNewTreatmentPlan(client: User): Observable<FullscriptCreateDynamicLinkResponse> {
    return fromPromise(this.lprxApi.post(`v3/fullscript/treatment-plans/${client.username}/draft`));
  }

  fullscriptTreatmentPlanDelete(id: string) {
    return fromPromise(this.lprxApi.delete(`v3/fullscript/treatment-plans/${id}`));
  }

  fullscriptChangePractitioner(practitionerId: any) {
    return fromPromise(this.lprxApi.post(`v3/fullscript/oauth/practitioner`, { practitionerId }));
  }

  fullscriptGetPatients(): Observable<IFullscriptPatientListItem[]> {
    return from(this.lprxApi.get('v3/fullscript/patients'));
  }

  fullscriptConnectToPatient(username: string, patientId: string): Observable<User> {
    return from(this.lprxApi.post<User>('v3/fullscript/connect', { username, patientId }));
  }

  fullscriptDisconnect(client: User) {
    return from(this.lprxApi.delete(`v3/fullscript/connect/${client.username}`));
  }

  deleteClientAccount(client: User): Observable<boolean> {
    const name = `${client.firstName} ${client.lastName}'s`;
    const msg = `Are you sure you want to delete ${name} account?`;
    if (confirm(msg)) {
      return fromPromise(this.lprxApi.users.delete(client.username)).pipe(
        alertHttpError(),
        switchMap(() => of(true)),
        tap((result) => {
          if (result) {
            this.toastr.warning(`Account for ${name} Deleted`, 'Deleted Account');
          }
        })
      );
    }
    return of(false);
  }

  pauseClientAccount(client: User) {
    return from(this.lprxApi.clients.pauseClientAccount(client.username));
  }

  needCredentialProof(): Observable<boolean> {
    return from(this.lprxApi.get<boolean>('v3/distributor-applications/proof-status')).pipe(
      map((p) => !p)
    );
  }

  setProof(uploadedFileKey: string): Observable<any> {
    return from(this.lprxApi.post('v3/distributor-applications/proof', { key: uploadedFileKey }));
  }

  createClient(dto: CreateClientDto): Observable<User> {
    return from(this.lprxApi.post<User>(`v3/clients`, dto));
  }

  sendActivationEmail(client: User) {
    return from(this.lprxApi.post(`v3/clients/${client.username}/send-invite`));
  }

  updateClientNutrientLimits(client: User, nutritionLimits: PlannerConfigNutrientLimits) {
    return from(this.lprxApi.clients.updateClientNutrientLimits(client.username, nutritionLimits));
  }

  updateProfile(updateProfileDto: DistributorUpdateProfileDto) {
    return from(this.lprxApi.distributors.updateProfile(updateProfileDto));
  }

  updateLogo(dto: DistributorUpdateLogoDto) {
    return from(this.lprxApi.distributors.updateLogo(dto));
  }

  updatePhoto(dto: DistributorUpdatePhotoDto) {
    return from(this.lprxApi.distributors.updatePhoto(dto));
  }

  addClientIngredientDislikes(client: User, dislikeEntry: string | string[]) {
    if (!Array.isArray(dislikeEntry)) {
      dislikeEntry = [dislikeEntry];
    }
    return from(this.lprxApi.clients.addClientIngredientDislikes(client.username, dislikeEntry));
  }

  removeClientIngredientDislikes(client: User, ingredient: string) {
    return from(this.lprxApi.clients.removeClientIngredientsDislikes(client.username, ingredient));
  }

  updateClientContactDetails(
    client: User,
    details: { email: string; firstName: string; lastName: string }
  ): Observable<User> {
    return from(this.lprxApi.clients.updateClientContactDetails(client.username, details)).pipe(
      switchMap(() => this.getClient(client.username, false)),
      tap((c) => this.clientsCache.set(c.username, c))
    );
  }

  cardPaymentsCapability() {
    return from(this.lprxApi.distributors.cardPaymentsCapability());
  }

  requestCardPaymentsCapability() {
    return from(this.lprxApi.distributors.requestCardPaymentsCapability());
  }

  disableCardPaymentsCapability() {
    return from(this.lprxApi.distributors.disableCardPaymentsCapability());
  }

  getStripeAccount(): Observable<Stripe.Account | null> {
    return from(this.lprxApi.distributors.getStripeAccount());
  }

  getCurrencies() {
    return from(this.lprxApi.distributors.getCurrencies());
  }

  updatePricing(dto: UpdatePricingDto) {
    return from(this.lprxApi.distributors.updatePricing(dto));
  }

  createCoupon$(coupon: ClientCoupon) {
    return from(this.lprxApi.distributors.createCoupon(coupon));
  }

  deleteCoupon(code: string) {
    return from(this.lprxApi.distributors.deleteCoupon(code));
  }

  requestHealthieIntegration$(healthieEmail: string) {
    return from(this.lprxApi.distributors.requestHealthieIntegration(healthieEmail));
  }

  getHealthieIntegration$() {
    return from(this.lprxApi.distributors.getHealthieIntegration());
  }

  distributorHasCapability$() {
    return from(this.lprxApi.get<{ result: boolean }>('v3/distributor-config/has-card-payments'));
  }

  newVioscreenForClient(username: string) {
    return from(this.lprxApi.post(`v3/vioscreens/${username}`));
  }

  newVioscreenForNonClient({ firstName, lastName, email }) {
    email = sanitizeEmail(email);
    return from(this.lprxApi.post(`v3/vioscreens`, { firstName, lastName, email }));
  }

  getVioscreens(): Observable<GetVioscreensResponse> {
    return from(this.lprxApi.get<GetVioscreensResponse>(`v3/vioscreens`));
  }

  fetchVioscreens(username: string) {
    return from(this.lprxApi.get<VioscreenEntity[]>(`v3/vioscreens/${username}`));
  }

  accessScreening(id: number) {
    return from(this.lprxApi.get<ViocareSessionTokenResponse>(`v3/vioscreens/access/${id}`));
  }

  deleteScreening(id: number) {
    return from(this.lprxApi.delete(`v3/vioscreens/${id}`));
  }

  getScreeningReport(id: number, reportType: VioscreenReportType) {
    return from(this.lprxApi.get(`v3/vioscreens/reports/${id}/${reportType}`));
  }

  sendFFQReminder(id: number) {
    return from(this.lprxApi.post(`v3/vioscreens/ffq-reminder/${id}`));
  }

  getTdCeuCouponCode() {
    return from(this.lprxApi.get<GetTdCeuCouponCodeResponse>('v3/integrations/td-ceu-coupon-code'));
  }

  getTeam() {
    return from(this.lprxApi.get<Result<PractitionerUserInterface[]>>('v3/team')).pipe(
      map((r: Result<PractitionerUserInterface[]>) => {
        r.data = plainToClass(User, r.data) as PractitionerUserInterface[];
        return r;
      })
    );
  }

  addTeamMember(request: EditTeamMemberRequest) {
    return from(this.lprxApi.post('v3/team', request));
  }

  removeTeamMember(username: string) {
    return from(this.lprxApi.delete(`v3/team/${username}`));
  }

  updateTeamMember(username: string, request: EditTeamMemberRequest) {
    return from(this.lprxApi.put(`v3/team/${username}`, request));
  }

  changePrimaryPractitioner(
    client: User,
    newPrimaryPractitioner: PractitionerUserInterface
  ): Observable<ChangePrimaryPractitionerResponse> {
    return from(
      this.lprxApi.patch<ChangePrimaryPractitionerResponse>(
        `v3/clients/${client.username}/primary-practitioner`,
        { practitioner: newPrimaryPractitioner.username }
      )
    );
  }
}
