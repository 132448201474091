import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  NgbActiveModal,
  NgbDropdownModule,
  NgbModalModule,
  NgbModule,
  NgbTypeaheadModule,
} from '@ng-bootstrap/ng-bootstrap';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';

// tslint:disable-next-line:no-implicit-dependencies
import 'froala-editor/js/plugins.pkgd.min.js';
// import { DragulaModule } from 'ng2-dragula';
import { NgxStripeModule } from 'ngx-stripe';
import { ToastrModule } from 'ngx-toastr';
import { AdminDistributorsService } from '../providers/admin/admin-distributors.service';
import { AdminMealPlansService } from '../providers/admin/admin-meal-plans.service';
import { AdminRecipesService } from '../providers/admin/admin-recipes.service';
import { AdminService } from '../providers/admin/admin.service';
import { ApiV2 } from '../providers/api.v2';
import { ApiV3 } from '../providers/api.v3';
import { WeeklyPlansService } from '../providers/apiv2/userwp';
import { Api } from '../providers/aws.api';
import { DynamoDB } from '../providers/aws.dynamodb';
import { AnonymousService } from '../providers/guest/anonymous.service';
import { LprxApiModule } from '../providers/lprx-api/lprx-api.module';
import { AccountModule } from './account/account.module';
import { ActivateAccountPasswordComponent } from './activate-account/activate-account-password/activate-account-password.component';
import { ActivateAccountService } from './activate-account/activate-account.service';
import { AdminLayoutService } from './admin/service/admin-layout.service';
import { AdminMasterMealPlanService } from './admin/service/admin-master-meal-plan.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './auth.service';
import { PendingSetupComponent } from './auth/pending-setup/pending-setup.component';
import { BasicsModule } from './basics/basics.module';
import { BuyModule } from './buy/buy.module';
import { ChangePasswordDialogComponent } from './change-password-dialog/change-password-dialog.component';
import { CreditCardInputModule } from './credit-card-input/credit-card-input.module';
import { PrimePurchaseService } from './distributor/prime/prime-checkout/prime-purchase.service';
import { PremiumAlertModalService } from './distributor/resources/distributor-folder-list-item/premium-alert-modal.service';
import { DistributorsServiceModule } from './distributors/distributors-service/distributors-service.module';
import { FacebookPixelModule } from './facebook-pixel/facebook-pixel.module';
import { GetPrimeComponent } from './get-prime/get-prime.component';
import { LayoutService } from './layout/layout.service';
import { LoadingModule } from './loading/loading.module';
import { MagicLinkComponent } from './magic-link/magic-link.component';
import { NutritionFactsLabelModule } from './nutrition-facts-label/nutrition-facts-label.module';
import { TermsHtmlModule } from './page/terms/terms-html/terms-html.module';
import { PasswordInputComponent } from './password-input/password-input.component';
import { PdfGeneratorModule } from './pdf-generator/pdf-generator.module';
import { DropService } from './planner/drop.service';
import { PlannerModule } from './planner/planner.module';
import { RecipeOpenerService } from './planner/recipe-opener.service';
import { RecipeSearchService } from './planner/recipe-search.service';
import { RecipeEditorComponentModule } from './recipe/recipe-editor/recipe-editor.component.module';
import { RecipeViewerModule } from './recipe/recipe-view/recipe-view.module';
import { AdminPlannerService } from './service/admin/admin-planner.service';
import { CdnService } from './service/cdn.service';
import { ClientAccountBillingService } from './service/client/client-account-billing.service';
import { ClientAccountService } from './service/client/client-account.service';
import { ClientMealPlanService } from './service/client/client-meal-plan.service';
import { ClientRecipesService } from './service/client/client-recipes-service';
import { ClientService } from './service/client/client.service';
import { DistributorAccountBillingService } from './service/distributor/distributor-account-billing.service';
import { DistributorAccountService } from './service/distributor/distributor-account.service';
import { DistributorBundlesService } from './service/distributor/distributor-bundles.service';
import { DistributorClientPlannerService } from './service/distributor/distributor-client-planner.service';
import { DistributorClientsService } from './service/distributor/distributor-clients.service';
import { DistributorMealPlansService } from './service/distributor/distributor-meal-plans.service';
import { DistributorRecipesService } from './service/distributor/distributor-recipes.service';
import { HeaderService } from './service/header.service';
import { IDGeneratorService } from './service/idgenerator.service';
import { MealPlansService } from './service/meal-plans.service';
import { NutrientService } from './service/nutrient.service';
import { PrinterService } from './service/printer.service';
import { RecipeCacheService } from './service/recipe-cache.service';
import { UserService } from './service/user.service';
import { VarService } from './service/var.service';
import { VersionCheckerService } from './service/version-checker.service';
import { SharedModule } from './shared/shared.module';
import { UnitSystemToggleModule } from './unit-system-toggle/unit-system-toggle.module';
import {
  AdminGuard,
  AuthGuard,
  DistributorGuard,
  PlannerGuard,
  UserGuard,
} from './utilities/guards';
import { DistributorWellnessProGuard } from './utilities/guards/distributor-wellness-pro-guard.service';
import { VerifyEmailChangeComponent } from './verify-email-change/verify-email-change.component';
import { MatNativeDateModule } from '@angular/material/core';
import {
  ButtonDirective,
  DangerButtonDirective, PrimaryButtonDirective,
  SecondaryButtonDirective
} from '../component-directives/button.directive';
import { LucideAngularModule } from 'lucide-angular';
import { lucideIcons } from './lucide-icons';
import { MatButtonModule } from '@angular/material/button';
import { MealPlannerSetupComponent } from './meal-planner-setup/meal-planner-setup.component';
import { HomeAccessIssueComponent } from './home-access-issue/home-access-issue.component';

@NgModule({
  declarations: [
    AppComponent,
    ActivateAccountPasswordComponent,
    ChangePasswordDialogComponent,
    PasswordInputComponent,
    MagicLinkComponent,
    GetPrimeComponent,
    VerifyEmailChangeComponent,
    PendingSetupComponent,
    MealPlannerSetupComponent,
    HomeAccessIssueComponent
  ],
  exports: [],
  imports: [
    BrowserModule,
    BasicsModule,
    AppRoutingModule,
    SharedModule,
    BrowserAnimationsModule,
    NgbModule,
    NgbTypeaheadModule,
    NgbModalModule,
    NgbDropdownModule,
    // DragulaModule.forRoot(),
    RecipeEditorComponentModule,
    HttpClientModule,
    PlannerModule,
    AccountModule,
    ToastrModule.forRoot(),
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    RecipeViewerModule,
    // NgxStripeModule.forRoot('pk_test_NVsBcwjbv5kQLaS1bchbuvnm'),
    NgxStripeModule.forRoot(),
    NutritionFactsLabelModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    BuyModule,
    UnitSystemToggleModule.forRoot(),
    LprxApiModule.forRoot(),
    FacebookPixelModule.forRoot(),
    DistributorsServiceModule,
    TermsHtmlModule,
    LoadingModule,
    PdfGeneratorModule,
    CreditCardInputModule,
    MatNativeDateModule,
    ButtonDirective,
    DangerButtonDirective,
    SecondaryButtonDirective,
    LucideAngularModule.pick(lucideIcons),
    MatButtonModule,
    PrimaryButtonDirective
  ],
  providers: [
    { provide: Window, useValue: window },
    ActivateAccountService,
    AdminLayoutService,
    Api,
    ApiV2,
    ApiV3,
    ClientService,
    AuthGuard,
    AdminGuard,
    UserGuard,
    PlannerGuard,
    DistributorGuard,
    DistributorWellnessProGuard,
    AuthService,
    DropService,
    DynamoDB,
    IDGeneratorService,
    LayoutService,
    AdminMasterMealPlanService,
    RecipeOpenerService,
    RecipeCacheService,
    // RecipeService,
    RecipeSearchService,
    VersionCheckerService,
    CdnService,
    HeaderService,
    PrinterService,
    VarService,
    UserService,
    AdminService,
    AdminRecipesService,
    AdminMealPlansService,
    AdminDistributorsService,
    AdminPlannerService,
    ClientRecipesService,
    ClientAccountService,
    ClientAccountBillingService,
    ClientMealPlanService,
    DistributorRecipesService,
    DistributorMealPlansService,
    DistributorAccountService,
    DistributorAccountBillingService,
    DistributorClientsService,
    DistributorClientPlannerService,
    DistributorBundlesService,
    AnonymousService,
    NgbActiveModal,
    NutrientService,
    WeeklyPlansService,
    MealPlansService,
    PremiumAlertModalService,
    PrimePurchaseService,
  ],
  bootstrap: [AppComponent],
  // entryComponents: [PlannerRecipeComponent, LandingPageComponent],
})
export class AppModule {}

declare var AWS: any;
