import { plainToClass } from 'class-transformer';
import Stripe from 'stripe';
import { ClientCoupon } from '../../../lprx-shared-lib/client-coupon';
import { DistributorUpdateLogoDto } from '../../../lprx-shared-lib/distributor-update-logo.dto';
import { DistributorUpdatePhotoDto } from '../../../lprx-shared-lib/distributor-update-photo.dto';
import { DistributorUpdateProfileDto } from '../../../lprx-shared-lib/distributor-update-profile';
import { GetAffiliateAccountResponse } from '../../../lprx-shared-lib/dto/get-affiliate-account-response';
import { GetAffiliateDashboardResponse } from '../../../lprx-shared-lib/dto/get-affiliate-dashboard-response';
import { Distributor } from '../../../lprx-shared-lib/entities/distributor';
import { DistributorType } from '../../../lprx-shared-lib/entities/distributor-type';
import { User } from '../../../lprx-shared-lib/entities/user/user';
import { UpdatePricingDto } from '../../../lprx-shared-lib/update-pricing.dto';
import { CheckForPendingProfileByEmailResult } from '../../../lprx-shared-lib/utils/types';
import { LprxApi } from '../api';

export class Distributors {
  constructor(private readonly lprxApi: LprxApi) {}

  distributor: Distributor;

  private distributorLastCheck: number = 0;
  private checkingCurrentPromise: Promise<Distributor>;

  current(): Promise<Distributor> {
    const timeSince = Date.now() - this.distributorLastCheck;
    if (this.distributor && timeSince < 1000) {
      console.log('Current Distributor: Cached:', this.distributor);
      return Promise.resolve(this.distributor);
    }

    if (this.checkingCurrentPromise) {
      console.log('Current Distributor: Waiting on Promise');
    } else {
      console.log('Current Distributor: Determining');
      this.checkingCurrentPromise = this.lprxApi.get(`v3/distributors/current`);
    }

    return this.checkingCurrentPromise
      .then((res) => {
        const distributor = plainToClass(Distributor, res);
        console.log('Current Distributor:', distributor);
        this.distributor = distributor;
        this.distributorLastCheck = Date.now();
        this.checkingCurrentPromise = null;
        return distributor;
      })
      .catch((e) => {
        this.checkingCurrentPromise = null;
        throw e;
      });
  }

  cleanUserAccount(distributorId: string): Promise<any> {
    return this.lprxApi.delete(`v3/distributors/clean-user/${distributorId}`);
  }

  getCards(): Promise<Stripe.Card[]> {
    return this.lprxApi.get(`v3/payment-methods`);
  }

  get(id: string): Promise<Distributor> {
    return this.lprxApi.get(`v3/distributors/${id}`).then((d) => plainToClass(Distributor, d));
  }

  getAffiliateDashboard(): Promise<GetAffiliateDashboardResponse> {
    return this.lprxApi.get(`v3/distributors/affiliate-dashboard`);
  }

  getAffiliateAccount(): Promise<GetAffiliateAccountResponse> {
    return this.lprxApi.get(`v3/distributors/affiliate`);
  }

  getMealPlan(distributorId: string, mealPlanVanityId: string) {
    console.log('getMealPlan', distributorId, mealPlanVanityId);
    return this.lprxApi.get(`v3/distributors/${distributorId}/meal-plans/${mealPlanVanityId}`);
  }

  updateProfile(updateProfileDto: DistributorUpdateProfileDto): Promise<Distributor> {
    return this.lprxApi.put(`v3/distributors/profile`, updateProfileDto);
  }

  updateLogo(dto: DistributorUpdateLogoDto): Promise<Distributor> {
    return this.lprxApi.put(`v3/distributors/logo`, dto);
  }

  updatePhoto(dto: DistributorUpdatePhotoDto): Promise<string> {
    return this.lprxApi.put(`v3/distributors/photo`, dto);
  }

  users(distributorId: string | null) {
    return distributorId
      ? this.lprxApi.get<User[]>(`v3/distributors/users`, { distributorId })
      : this.lprxApi.get<User[]>(`v3/distributors/users`);
  }

  cardPaymentsCapability() {
    return this.lprxApi.get<Stripe.Capability>('v3/distributors/card-payments-capability');
  }

  requestCardPaymentsCapability() {
    return this.lprxApi.put('v3/distributors/card-payments-capability');
  }

  disableCardPaymentsCapability() {
    return this.lprxApi.delete('v3/distributors/card-payments-capability');
  }

  getStripeAccount(): Promise<Stripe.Account | null> {
    return this.lprxApi.get<Stripe.Account | null>('v3/distributors/stripe-account');
  }

  getCurrencies() {
    return this.lprxApi.get('v3/distributors/currencies');
  }

  updatePricing(dto: UpdatePricingDto) {
    return this.lprxApi.post('v3/distributor-config/pricing', dto);
  }

  createCoupon(coupon: ClientCoupon): Promise<ClientCoupon[]> {
    return this.lprxApi.post('v3/distributor-config/coupons', coupon);
  }

  deleteCoupon(code: string) {
    return this.lprxApi.delete<ClientCoupon[]>('v3/distributor-config/coupons/' + code);
  }

  requestHealthieIntegration(healthieEmail: string) {
    return this.lprxApi.post(`v3/integrations/healthie`, { healthieEmail });
  }

  getHealthieIntegration() {
    return this.lprxApi.get(`v3/integrations/healthie`);
  }

  switchType(distributorId: string, type: DistributorType): Promise<Distributor> {
    return this.lprxApi
      .post<Distributor>(`v3/distributors/${distributorId}/type`, { type })
      .then((distributor) => plainToClass(Distributor, distributor));
  }

  hasPendingProfile(email: string) {
    return this.lprxApi.get<CheckForPendingProfileByEmailResult>(
      'v3/distributors/pending-profile',
      { email }
    );
  }

  sendPendingProfile(email: string) {
    return this.lprxApi.post<{ result: boolean }>('v3/distributors/pending-profile', { email });
  }

  getPrimeSubscription(distributor: Distributor) {
    return this.lprxApi.get<Stripe.Subscription>(
      `v3/distributors/${distributor.id}/prime-subscription`
    );
  }

  getSubscriptions(distributor: Distributor) {
    return this.lprxApi.get<Stripe.Subscription[]>(
      `v3/distributors/${distributor.id}/subscriptions`
    );
  }

  disconnectSubscription(distributor: Distributor) {
    return this.lprxApi.delete<Distributor>(`v3/distributors/${distributor.id}/prime-subscription`);
  }

  connectSubscription(distributor: Distributor, subscriptionId: string) {
    return this.lprxApi.post<Distributor>(`v3/distributors/${distributor.id}/prime-subscription`, {
      subscriptionId,
    });
  }
}
